import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";

export default class MobileMenu extends Component {

    menu = (e) => {

        // Mobile Menu
        const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
        const mdSidebarBody = document.getElementById("mb-sidebar-body");

        if(e.target.id === "mb-sidebar-btn"){
            mdSidebarOverlay.classList.toggle("visible");
            mdSidebarBody.classList.toggle("opened");
        }
        else{
            mdSidebarOverlay.classList.remove("visible");
            mdSidebarBody.classList.remove("opened");
        }
    }

    render() {
        return (
            <Styles>
                {/* Mobile Menu */}
                <section className="mobile-menu-area">
                    <Container>
                        <Row>
                            <Col md="0" sm="12">
                                <div className="mb-logo-area d-flex justify-content-between">
                                    <div className="mb-logo-box d-flex">
                                        <div className="mb-logo">
                                            <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/general/logo.gif"} alt="" /></Link>
                                        </div>
                                        <div className="hm-button">
                                            <i className="fa fa-bars" onClick={this.menu} id="mb-sidebar-btn"></i>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Mobile Menu Sidebar */}
                <section className="mb-sidebar" id="mb-sidebar-body">
                    <div className="mb-sidebar-heading d-flex justify-content-between">
                        <div><h5>Menu</h5></div>
                        <div onClick={this.menu} id="close-mb-sidebar"><i className="fa fa-window-close"></i></div>
                    </div>
                    <div className="mb-sidebar-menu" onClick={this.menu}>
                        <Link className="mb-menu-item" to={process.env.PUBLIC_URL + '/'}>Home</Link>
                        <Link className="mb-menu-item" to={process.env.PUBLIC_URL + '/Programmes'}>Programmes</Link>
                        <Link className="mb-menu-item" to={process.env.PUBLIC_URL + '/AboutUs'}>About Us</Link>
                        <Link className="mb-menu-item" to={process.env.PUBLIC_URL + '/Directors'}>Board of Directors</Link>
                        <Link className="mb-menu-item" to={process.env.PUBLIC_URL + '/Alumni'}>Alumni</Link>
                        <Link className="mb-menu-item" to={process.env.PUBLIC_URL + '/Media'}>Media</Link>
                        <Link className="mb-menu-item" to={process.env.PUBLIC_URL + "/Gallery"}>Photo Gallery</Link>
                        <Link className="mb-menu-item" to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                    </div>
                </section>
                <div className="mb-sidebar-overlay" onClick={this.menu} id="mb-sidebar-overlay"></div>
            </Styles>
        )
    }
}
