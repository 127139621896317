import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './BackToTop';
import { Styles } from "./styles/footer.js";

function FooterTwo() {
    return (
        <Styles>
            {/* Footer Two */}
            <footer className="footer2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/footer-bg.jpg)` }}>
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="f-newsletter">
                                <div className="box-content">
                                    <h5>Get in Touch</h5>
                                    <p>
                                        Kemp’s center for learning,<br/>
                                        Adichunchanagiri road (Opposite Ganabharati) K block,<br/>
                                        Kuvempunangar, Mysore,Pincode - 570023.<br/>
                                        emailhiremath@gmail.com<br/>
                                        +91 97401 28782</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="f-links">
                                <h5>Quick Links</h5>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <ul className="list-unstyled">
                                            <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Home</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Programmes"}><i className="las la-angle-right"></i>Programmes</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/AboutUs"}><i className="las la-angle-right"></i>About Us</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4">
                                        <ul className="list-unstyled">
                                            <li><Link to={process.env.PUBLIC_URL + "/Directors"}><i className="las la-angle-right"></i>Board of Directors</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Alumni"}><i className="las la-angle-right"></i>Alumni</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Media"}><i className="las la-angle-right"></i>Media</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4">
                                        <ul className="list-unstyled">
                                            <li><Link to={process.env.PUBLIC_URL + "/Gallery"}><i className="las la-angle-right"></i>Photo Gallery</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/Contact"}><i className="las la-angle-right"></i>Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="copytext-area text-center">
                                <p>Copyright &copy; 2022 | Designed &#38; Developed by <a href={process.env.PUBLIC_URL + "/"} target="_blank" rel="noopener noreferrer">Shoonyeka Solutions Mysuru</a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Back To Top  */}
                <BackToTop />
            </footer>
        </Styles>
    );
}

export default FooterTwo
