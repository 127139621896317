import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/header.js";
import MobileMenu from './MobileMenu.js';
import ModalImage from "react-modal-image";
import { doc, getDoc } from 'firebase/firestore';
import NoData from "../../components/common/NoData";
import Loading from "../../components/common/Loading";
import { firestore } from '../../Config/FbConfig';

export default class Header extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "SwarasankulaSchool", "Next");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Video: value.Video !== undefined || value.Video === "" ? value.Video : "999",
                        Image: value.Image,
                        Header: value.Header,
                        Date: value.Date
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div>
                <Styles>
                    <section className="top-bar">
                        <Container>
                            <Row>
                                <Col lg="5">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/general/logo.gif"} loop style={{ width: "32.5rem", marginTop: "1rem" }} alt="none"/>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="menu-box bar-right d-flex justify-content-center">
                                        <ul className="nav menu-nav">
                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}><i className="fas fa-phone" />&nbsp;&nbsp;+91 97401 28782</div></li>
                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}><i className="fas fa-envelope" />&nbsp;&nbsp;emailhiremath@gmail.com</div></li>
                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}>|</div></li>
                                            <li className="nav-item"><div className="nav-link"><a style={{ color: "white" }} rel="noopener noreferrer" href="https://www.youtube.com/channel/UCjVdAok_K_KjVUsJUcIIR9g" target="_blank"><i className="fab fa-youtube" />&nbsp;&nbsp;Youtube</a></div></li>
                                        </ul>
                                    </div>
                                    {
                                        this.state.Datas.length === 0 ? <Loading /> :
                                            this.state.Datas === "NO_999" ? <NoData /> :
                                                this.state.Datas.map((data, i) => (
                                                    <div className="row paddingtop-2" key={i}>
                                                        <div className="col-lg-5">
                                                            <div className="gallery-box">
                                                                <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7" style={{ margin: "auto" }}>
                                                            <p style={{ color: "maroon", fontWeight: "bold" }}>{data.Date.split("-")[2] + "-" + data.Date.split("-")[1] + "-" + data.Date.split("-")[0]}</p>
                                                            <p style={{ color: "black" }}>{data.Header}</p>
                                                            <hr />
                                                            {
                                                                data.Video !== "999" ?
                                                                    <a target="_blank" href={data.Video} style={{ color: "black", fontWeight: "bold" }} rel="noopener noreferrer">More Info Click Here</a> :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Logo Area */}
                    <section className="header-area">
                        <Container>

                        </Container>
                    </section>

                    <section className="top-bar-info">
                        <Container>
                            <div className="d-flex justify-content-center">
                                <ul className="nav menu-nav">
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>HOME</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Programmes"}>Programmes</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/AboutUs"}>About Us</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Directors"}>Board of Directors</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Alumni"}>Alumni</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Media"}>Media</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Gallery"}>Photo Gallery</Link></li>
                                    <div className="line">|</div>
                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/Contact"}>Contact</Link></li>
                                </ul>
                            </div>
                        </Container>
                    </section>
                </Styles>
                <MobileMenu />
            </div>
        )
    }
}