import React, { Component } from 'react';
import Header from '../components/common/Header';
import Banner from '../components/common/Banner';
import Footer from '../components/common/Footer';

export default class AboutUs extends Component {

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="About Us" image="banners/swarasankula02.jpg" />

        {/* Text Field */}
        <div className="container ttm-service-description">
          <br /><br />
          <div className="row">
            <div className="col-md-4">
              <div className="ttm_single_image-wrapper">
                <img className="swaraimage img-fluid" src={process.env.PUBLIC_URL + "/assets/images/about/main1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
              </div>
            </div>
            <div className="mob-only"><br /></div>
            <div className="col-md-8 justify" style={{ margin: "auto" }}>
              <div className="paddingtop-2 section-title with-desc text-left clearfix">
                <div className="title-header" style={{ borderTopColor: "red" }}>
                  <h4 className="title"><small style={{ color: "maroon", fontWeight: "bold", fontSize: "1.25rem" }}>Our Principal</small><br />Pt. Veerabhadraiah Hiremath</h4>
                </div>
              </div>
              <p className="swaratext1" data-wow-delay="0.3s">Born in Yeragal – a remote village of Sindhagi Taluk, Bijapur district, to Shankaraiah- Annapoorna couple on June 1 st , 1966. His father basically agriculturist, was also playing harmonium to the Bhajan team of the village. At the very early age of 10, on his own, he had learnt to play a bhajan.</p><br />
              <p className="swaratext1" data-wow-delay="0.3s">Impressed by his interest and talent in music, the village Bhajan tearm helped him to join the famous “Veereshwara punyashrama” of Gadag. He learnt Hindustani music from Dr. Puttaraja Gawai for 10 yrs. Has passed Karnataka state music exams Junior, senior and Vidwat grade (3 rd rank for the state) in good grades. Has also passed “Sangeeta Visharada” exam of Gandharva Mahavidyalaya in highest grades.</p><br />
              <p className="swaratext1" data-wow-delay="0.3s">Initially started his Singing and teaching career in Gadag Ashram, then came to Mysore in the year.</p><br />
              <p className="swaratext1" data-wow-delay="0.3s">Since then has worked in many musical institutes and trusts. Is a trustee and Secretary of “Swarasankula” trust. Has been teaching music in Kemp’s learning centre and Arivu Vidyasamsthe.</p>
            </div>
          </div>
          <br /><br />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}