import React, { Component } from 'react';
import Header from '../components/common/Header';
import LanderBlock from '../components/LanderBlock';
import ImageGallery from '../components/ImageGallery';
import Footer from '../components/common/Footer';
import ModalImage from "react-modal-image";
import { doc, getDoc } from 'firebase/firestore';
import NoData from "../components/common/NoData";
import Loading from "../components/common/Loading";
import { firestore } from '../Config/FbConfig';

export default class Home extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "SwarasankulaSchool", "Next");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Video: value.Video !== undefined || value.Video === "" ? value.Video : "999",
                        Image: value.Image,
                        Header: value.Header,
                        Date: value.Date
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Lander Slider New */}
                <LanderBlock />

                {/*Special News Section*/}
                <div className="container show-on-mob">
                {
                    this.state.Datas.length === 0 ? <Loading /> :
                        this.state.Datas === "NO_999" ? <NoData /> :
                            this.state.Datas.map((data, i) => (
                                <div className="row paddingtop-2" key={i}>
                                    <div className="col-lg-5">
                                        <div className="gallery-box">
                                            <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7" style={{ margin: "auto" }}>
                                        <p className="paddingtop-1" style={{ color: "maroon", fontWeight: "bold" }}>{data.Date.split("-")[2] + "-" + data.Date.split("-")[1] + "-" + data.Date.split("-")[0]}</p>
                                        <p style={{ color: "black" }}>{data.Header}</p>
                                        <hr />
                                        {
                                            data.Video !== "999" ?
                                                <a target="_blank" href={data.Video} style={{ color: "black", fontWeight: "bold" }} rel="noopener noreferrer">More Info Click Here</a> :
                                                null
                                        }
                                    </div>
                                </div>
                            ))
                }
                </div>

                {/* Text Field */}
                <div className="container ttm-service-description">
                    <br /><br />
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <iframe width="672" height="378" src="https://www.youtube.com/embed/iydX18l8MT8" title="Sa Ri Ga Ma Pa Da Ni Sa SAPTASWARA  Sheershike Geete" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="paddingtop-2"><br /></div>
                        <div className="col-md-5">
                            <div className="ttm_single_image-wrapper">
                                <img className="swaraimage img-fluid" src={process.env.PUBLIC_URL + "/assets/images/home/main1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                            </div>
                        </div>
                        <div className="mob-only"><br /></div>
                        <div className="col-md-7 justify" style={{ margin: "auto" }}>
                            <div className="section-title with-desc text-left clearfix">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">The School</h4>
                                </div>
                            </div>
                            <p className="swaratext1" data-wow-delay="0.3s">Swarasankula Music School, Mysore imparts training in Hindustani Classical Music</p><br />
                            <li className="swaratext1">Learn from stalwarts - Under Guidance Stalwarts of Hindustani Classical Music</li>
                            <li className="swaratext1">Performance Support - Support for performances and improvements</li>
                            <li className="swaratext1">Structured Approach - An approach that suits everyone and anyone</li>
                            <li className="swaratext1">Open for one and all - Swarasankula Music Schools offers space and oppurtunity for all age, sex, caste and creed</li>
                            <li className="swaratext1">Cultural Heritage - Transfering Cultural Heritage</li>
                            <li className="swaratext1">Special Classes - Special and regular classes for Hindustani Classical</li>
                        </div>
                    </div>
                    <br /><br />
                </div>

                {/* Image Gallery Area */}
                <ImageGallery />

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}