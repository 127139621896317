import React from 'react';
import { BrowserRouter as BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global";
import { ParallaxProvider } from 'react-scroll-parallax';

import Home from "./pages/Home";
import Programmes from "./pages/Programmes";
import AboutUs from "./pages/AboutUs";
import Directors from "./pages/Directors";
import Alumni from "./pages/Alumni";
import Media from "./pages/Media";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";

export default function App() {
    return (
        <ParallaxProvider>
            <BrowserRouter basename="/"> 
                <GlobalStyle />
                <ScrollToTop />
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={Home} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Programmes"}`} component={Programmes} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/AboutUs"}`} component={AboutUs} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Directors"}`} component={Directors} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Alumni"}`} component={Alumni} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Media"}`} component={Media} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Gallery"}`} component={Gallery} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/Contact"}`} component={Contact} />
                </Switch>
            </BrowserRouter>
        </ParallaxProvider>
    )
}