import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Banner from '../components/common/Banner';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { Styles } from './styles/members.js';

export default class Directors extends Component {

  render() {

    const Datas = [
      {
        "id": "1",
        "memberImg": "member-1.jpg",
        "memberName": "Basavegowda C"
      },
      {
        "id": "2",
        "memberImg": "member-2.jpg",
        "memberName": "Nityananda P"
      },
      {
        "id": "3",
        "memberImg": "member-3.jpg",
        "memberName": "Umashankar K"
      },
      {
        "id": "4",
        "memberImg": "member-4.jpg",
        "memberName": "Dr Bhaskar M S"
      },
      {
        "id": "5",
        "memberImg": "member-5.jpg",
        "memberName": "Lakshminarayana S"
      },
      {
        "id": "6",
        "memberImg": "member-6.jpg",
        "memberName": "Rakesh C R"
      },
      {
        "id": "7",
        "memberImg": "member-7.jpg",
        "memberName": "Venkatesh Bhat"
      },
      {
        "id": "8",
        "memberImg": "member-8.jpg",
        "memberName": "Vijay Kumar"
      },
      {
        "id": "9",
        "memberImg": "member-9.jpg",
        "memberName": "Dr. Manohara M C"
      },
      {
        "id": "10",
        "memberImg": "member-10.jpg",
        "memberName": "Shreerama Bhat"
      },
      {
        "id": "11",
        "memberImg": "member-11.jpg",
        "memberName": "Smt. Poornima Hiremath"
      },
      {
        "id": "12",
        "memberImg": "member-12.jpg",
        "memberName": "Veerabhadraiah Hiremath"
      }
    ]

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Board of Directors" image="banners/swarasankula03.jpg" />

        <Styles>
          <div className="main-wrapper member-page">
            <section className="member-page-area">
              <Container>
                <Row>
                {
                  Datas.map((data, i) => (

                      <Col xl="3" lg="4" md="0" key={i}>
                        <div className="member-img">
                          <img src={process.env.PUBLIC_URL + `/assets/images/member/${data.memberImg}`} alt="" className="swaraimage1 img-fluid" />
                        </div>
                        <h6 className="paddingtop-2" style={{ color: "black", textAlign:"center" }}>{data.memberName}</h6>
                        <br/>
                      </Col>
                  ))
                }
                </Row>
              </Container>
            </section>
          </div>
        </Styles>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
