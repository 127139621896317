import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD8ZhabNNrUBZ_Ko3zGMYeqoOa4BHcLsQE",
  authDomain: "swarasankula-admin.firebaseapp.com",
  projectId: "swarasankula-admin",
  storageBucket: "swarasankula-admin.appspot.com",
  appId: "1:778342527687:web:17c173ff11439dacef44e2",
  measurementId: "G-8J51NE1GYD"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {auth, firestore , storage};