import React, { Component } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { Styles } from './styles/banner.js';

export default class Banner extends Component {
    render() {
        return (
            <Styles>
                <section className="bitHeader-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${this.props.image})` }}>
                    <Container>
                        <Row>
                            <Col md="12">
                                <h2 className="title">{this.props.title}</h2>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}
