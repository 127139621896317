import React, { Component } from 'react';
import './styles/SpinnerStyle.css';

export default class Loading extends Component {
  render() {
    return (
      <div className="main">
        <div className="loader"></div>
        <h6><b>Loading, please wait...</b></h6>
      </div>
    )
  }
}
